import de from './messages.de';
import fr from './messages.fr';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de-CH',
  messages: {
    de,
    fr,
  },
}));
